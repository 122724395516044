.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(254, 242, 242, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    .loading-icon {
        height: 20vh;
        width: 40vh;
        border: 2px solid black;
        background-color: aqua;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}