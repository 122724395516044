@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.login-body {
    display: flex;
    height: 1000px;
    background-size: cover;
    background-position: center;
    position: relative;

    .left-image {
        position: fixed;
        z-index: 1;
        width: 20%;
        height: 100vh;
        background-size: cover;
        background-position: center;
        object-fit: contain;
        border: 1px solid #fff;
            
    }

    .right-image {
        position: fixed;
        z-index: 1;
        right: 0px;
        width: 20%;
        height: 100vh;
        background-size: cover;
        background-position: center;
        object-fit: contain;
        border: 1px solid #fff;
            
    }

    .login-form {
        width: 60%;
        height: 100%;

        .image1 {
            margin-left: 42vw;
            margin-top: 20px;
            margin-bottom: 20px;
            height: 11vh;
            width: 15vw;
            background-size: cover;
            background-position: center;
            object-fit: cover;
            border: 1px solid #fff;
            border-radius: 2px;

        
        }

        .image2 {
            margin-left: 33vw;
            height: 15vh;
            width: 33vw;
            margin-bottom: 20px;
            background-size: cover;
            background-position: center;
            border: 1px solid #fff;
            border-radius: 2px;
        }

        .image3 {
            margin-left: 33vw;
            height: 36vh;
            width: 33vw;
            background-size: cover;
            background-position: center;
            border: 1px solid #fff;
            border-radius: 2px;
        }

        .wrapper {
            position: absolute;
            top: 75%;
            left: 50%;
            width: 40%;
            transform: translate(-50%, -50%);
            background: transparent;
            backdrop-filter: blur(10px);
            border: 2px solid #fff;
            color: black;
            margin-left: auto;
            padding: 30px 40px;
            border-radius: 20px;
            font-family: 'Poppins', sans-serif;

            h1 {
                text-align: center;
                color: #fff;
            }

            .input-box {
                position: relative;
                width: auto;
                height: 50px;
                margin: 20px;

                input {
                    width: 100%;
                    height: 100%;
                    background: transparent;
                    outline-color: green;
                    border-color: black;
                    border-radius: 20px;
                    margin: 5px;
                    font-size: 16px;
                    color: #fff;
                    font-weight: 550;
                }

                input::placeholder {
                    color: #fff;
                }

                .icon {
                    position: absolute;
                    right: 20px;
                    top: 70%;
                    transform: translateY(-50%);
                    font-size: 20px;
                }
            }

            button {
                width: 80%;
                position: relative;
                height: 45px;
                border: none;
                outline: none;
                border-radius: 20px;
                font-size: 16px;
                color: #333;
                font-weight: 700;
                box-shadow: 0 0 10px rgba (0, 0, 0, .1);
                margin-bottom: 20px;
                text-align: center;
                transform: translateX(15%);


            }

            .remember-me {
                font-size: 20px;
                margin: 10px;
                margin-left: 20px;
                font-weight: 600;


            }

            .button-class {
                text-align: center;
                background-color: red;
            }

            .error {
                color: rgb(248, 0, 0);
                padding-left: 25px;
                padding-top: 5px;
                font-size: 17px;
                font-style: italic;
            }

            .btn-login {
                .nav-link {
                    color: #000000;
                    font-size: 20px;
                    font-weight: 600;
                    text-align: center;
                    text-decoration: none;
                }

            }
        }
    }
}

.login-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f5f5f5;
    border-top: 1px solid #fff;
    background-color: #333;
    

    .f-infor {
        background-color: cadetblue;
        width: 450px;
        margin: 30px 0px;
        padding: 10px;
    }

    div {
        max-width: 650px;
    }

    .f-image1 {
        height: 200px;
        width: 300px;
        background-size: cover;
        margin-bottom: 20px;
    }

    .f-contact {
        display: flex;
        width: 450px;
        justify-content: space-between;
        margin-bottom: 20px;

        .facebook {
            background-color: blue;

            height: 75px;

        }

        .f-text {
            display: flex;
            background-color: yellow;
            height: 75px;
            width: 200px;
            align-items: center;
            justify-content: center;
            color: black;
        }

        .instagram {
            background-color: red;

            height: 75px;

        }
    }
}

@media screen and (max-width: 1000px) {
    .login-body {
        .login-form{
            .image1 {
                object-fit: contain;
                height: 11vh;
                width: 16vw;
            }

            .image2 {
                height: 14vh;

            }

            .image3 {
                height: 28vh;
                width: 33vw;
                
            }

            .wrapper{
                top: 70%;
                
                input::placeholder {
                    color: #fff;
                    font-size: 14px;
                    padding-left: 5px;
                }

                .remember-me {
                    font-size: 14px;
                }
            }

            
        }
    }

    .login-footer {
        .f-contact {
            width: 42vw;
        }
        .f-image1 {
            height: 25vh;
            width: 38vw;
        }
        .f-infor {
            width: 42vw;
            margin: 30px 0px;
            padding: 10px;
        }
    }
}

@media screen and (max-width: 800px) {


    .login-body{
        .login-form {
            .image1 {
                margin-left: 40vw;
                height: 11vh;
                width: 19vw;
            }
        }

        
    }
   
    
    
}
@media screen and (max-width: 600px) {
    .login-body {
        .login-form {
            .image1 {
                margin-left: 38vw;
                width: 24vw;
            }

            .image2 {
                margin-left: 30vw;
                height: 15vh;
                width: 40vw;
            }

            .image3 {
                margin-left: 30vw;
                height: 30vh;
                width: 40vw;
            }

            .wrapper {
                .input-box {
                margin: 10px 0px;
                padding:0px;
                font-size: 14px;
                }

                input::placeholder {
                    color: #fff;
                    font-size: 14px;
                    padding-left: 5px;
                }
            }
        }
    }

    .login-footer {
        .f-contact {
           
        }
        .f-image1 {
           
        }
        .f-infor {
            
        }
    }

}

@media screen and (max-width: 500px) {

    .login-container {
        width: auto;
        height: 178vh;
    }

    .login-body {
        .left-image {
            display: none;
        }

        .right-image {
            display: none;
        }

        .login-form {
            .image1 {
                width: 43vw;
                height: 9vh;
                margin-left: 29vw;
            }

            .image2 {
                width: 80vw;
                height: 14vh;
                margin-left: 10vw;
            }

            .image3 {
                width: 80vw;
                height: 30vh;
                margin-left: 10vw;
            }

            .wrapper{
                width: 76vw !important;
                top: 78%;
            }
        }

        
    }

    .login-footer {
        
        .f-infor {
            width: 80vw;
            height: auto;
            margin: 10px 0px;
            padding: 10px;
        }

        .f-contact {
            width: 80vw;
            height: auto;
        }

        .f-image1 {
            width: 60vw;
            height: 18vh;
        }

        
    }
}